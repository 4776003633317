import React from 'react';
import ReactDOM from "react-dom/client";
import App, {route, group} from 'library/App';
import ServiceWorker from 'library/ServiceWorker';

// Landing page (with login)
route('/@platform/Cities/CitiesView');
route('/login.html@platform/Login/LoginView'); // Old login

// Reset password
route('/reset-password.html@platform/ResetPassword/ResetPasswordView');

// Reload
route('/reload.html@platform/Reload');

// Building overview
route('/buildings/:buildings_uuid/overview.html@platform/Building/BuildingView', ['elevators@get_building_elevators', 'buildings@get_single_building']);

group(['USER', 'ADMIN'], () => {

    // Elevator survey
    route('/buildings/:buildings_uuid/elevators/:elevators_uuid/survey.html@platform/Building/Survey/SurveyView', ['buildings@get_single_building', 'elevators@get_building_elevators', 'floors@get_elevator_floors', 'elevator@get_single_elevator']);

    // Elevator survey (selected floor)
    route('/buildings/:buildings_uuid/elevators/:elevators_uuid/floors/:floors_uuid/survey.html@platform/Building/Survey/SurveyView', ['buildings@get_single_building', 'elevators@get_building_elevators', 'elevator@get_single_elevator', 'floors@get_elevator_floors', 'floor@get_single_floor']);

    // Elevator 3D
    route('/buildings/:buildings_uuid/elevators/:elevators_uuid/3d.html@platform/Building/Elevator3d/Elevator3dView', ['elevators@get_building_elevators', 'buildings@get_single_building', 'models@get_model', 'positions@get_elevator_positions', 'panoramas@get_elevator_panoramas']);

    // User management
    route('/user/management.html@platform/UserManagement/UserManagementView', 'accounts@get_account');

    // Favorities
    route('/favorities.html@platform/Favorities/FavoritiesView', 'favorities@get_account_favorities');

    // Transactions
    route('/transactions.html@platform/Transactions/TransactionsView', ['transactions@get_transactions', 'accounts@get_account']);
});

// System login
route('/system/login.html@system/login/LoginView');

group('ADMIN', () => {

    route('/system/home.html@system/home/HomeView');

    // Companies
    route('/system/companies/list.html@system/CompaniesList/CompaniesListView', 'companies@get_all_companies', [

        route('/system/companies/add.html@system/CompaniesAdd/CompaniesAddView'),
        route('/system/companies/:companies_uuid/edit.html@system/CompaniesEdit/CompaniesEditView', 'companies@get_single_company'),

        route('/system/companies/:companies_uuid/users/list.html@system/CompanyUsersList/CompanyUsersListView', 'company_users@get_all_company_users', [

            route('/system/companies/:companies_uuid/users/add.html@system/CompanyUsersAdd/CompanyUsersAddView', 'accounts@get_accounts_without_company')
        ]),

        route('/system/companies/:companies_uuid/pending-transactions/list.html@system/PendingTransactionsList/PendingTransactionsListView', 'transactions@get_pending_transactions'),
        route('/system/companies/:companies_uuid/transaction-batches/list.html@system/TransactionBatchesList/TransactionBatchesListView', 'batches@get_company_transaction_batches', [

            route('/system/companies/:companies_uuid/transaction-batches/:transaction_batches_uuid/list.html@system/BatchTransactionsList/BatchTransactionsListView', 'transactions@get_batch_transactions')
        ]),

        route('/system/companies/:companies_uuid/pricing.html@system/CompanyPricing/CompanyPricingView', 'company_pricing@get_company_pricing')
    ]);

    // Accounts
    route('/system/accounts/list.html@system/AccountsList/AccountsListView', 'accounts@get_accounts', [

            route('/system/accounts/add.html@system/AccountsAdd/AccountsAddView'),
            route('/system/accounts/:accounts_uuid/update.html@system/AccountsUpdate/AccountsUpdateView', 'account@get_account'),

            route('/system/accounts/:accounts_uuid/privileges/list.html@system/PrivilegesList/PrivilegesListView', 'privileges@get_account_privileges', [

                route('/system/accounts/:accounts_uuid/privileges/add.html@system/PrivilegesAdd/PrivilegesAddView')
            ])
        ]
    );

    // Buildings
    route('/system/buildings/list.html@system/BuildingsList/BuildingsListView', 'buildings@get_all_buildings', [

        route('/system/buildings/add.html@system/BuildingsAdd/BuildingsAddView'),
        route('/system/buildings/:buildings_uuid/edit.html@system/BuildingsEdit/BuildingsEditView', 'buildings@get_single_building'),

        // Elevators
        route('/system/buildings/:buildings_uuid/elevators/list.html@system/ElevatorsList/ElevatorsListView', 'elevators@get_building_elevators',  [

            route('/system/buildings/:buildings_uuid/elevators/add.html@system/ElevatorsAdd/ElevatorsAddView'),
            route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/edit.html@system/ElevatorsEdit/ElevatorsEditView', ['elevators@get_single_elevator', 'surveys@get_elevator_surveys']),

            // Surveys
            route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/surveys/list.html@system/SurveysList/SurveysListView', 'surveys@get_elevator_surveys', [

                route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/surveys/add.html@system/SurveysAdd/SurveysAddView'),
                route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/surveys/:surveys_uuid/edit.html@system/SurveysEdit/SurveysEditView', 'surveys@get_single_survey'),

                // Panoramas
                route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/surveys/:surveys_uuid/panoramas/list.html@system/PanoramasList/PanoramasListView', 'panoramas@get_survey_panoramas', [

                    route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/surveys/:surveys_uuid/panoramas/add.html@system/PanoramasAdd/PanoramasAddView')
                ])
            ]),

            // Floors
            route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/floors/list.html@system/FloorsList/FloorsListView', 'floors@get_elevator_floors', [

                route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/floors/add.html@system/FloorsAdd/FloorsAddView'),
                route('/system/buildings/:buildings_uuid/elevators/:elevators_uuid/floors/:floors_uuid/edit.html@system/FloorsEdit/FloorsEditView')
            ])
        ])
    ]);

    // Usage terms    
    route('/system/usage-terms/list.html@system/UsageTermsList/UsageTermsListView', 'usage_terms@get_all_usage_terms', [

        route('/system/usage-terms/add.html@system/UsageTermsAdd/UsageTermsAddView'),
        route('/system/usage-terms/:usage_terms_uuid/edit.html@system/UsageTermsEdit/UsageTermsEditView', 'usage_terms@get_single_usage_terms')
    ]);

    // Survey requests
    route('/system/survey-requests/list.html@system/SurveyRequestsList/SurveyRequestsListView', 'survey_requests@get_all_survey_requests', [

        route('/system/survey-requests/:survey_requests_uuid/edit.html@system/SurveyRequestsEdit/SurveyRequestsEditView', 'survey_requests@get_single_survey_request')
    ]);

    // BIM requests
    route('/system/bim-requests/list.html@system/BimRequestsList/BimRequestsListView', 'bim_requests@get_all_bim_requests', [

        route('/system/bim-requests/:bim_requests_uuid/edit.html@system/BimRequestsEdit/BimRequestsEditView', 'bim_requests@get_single_bim_request')
    ]);

    // Expired surveys
    route('/system/expired-surveys/list.html@system/ExpiredSurveysList/ExpiredSurveysListView', 'surveys@get_expired_surveys');
});

ReactDOM.createRoot(document.getElementById("root")).render(

    // <React.StrictMode>

        <App />

    // </React.StrictMode>
);

ServiceWorker.register();
