import {Preloading, Loading, Alert, Confirm} from 'library/components';

const config = {

    defaultLang: 'en',
    defaultMode: 'light',
    defaultTheme: 'platform',

    redirect: {

        forbidden: () => {

            return '/';
        }
    },

    components: {

        loading: Loading,
        preloading: Preloading,
        alert: Alert,
        confirm: Confirm
    },

    endpoints: {

        refreshAccessToken: {

            endpoint: '/api/auth/refresh_access_token/',
            method: 'get'
        }
    }
};

export default config;
